
:root {
  --theme-color1: #fae6b1;
  --theme-color2: #ffa101;
  /* --theme-color3: #b3dee5;   */
  /* --theme-color3: #ecf6f8; */
  --theme-color3: #d9eef2;
  --theme-color4: #31525b;
  --theme-color5: #f2f2f2;
  --theme-color6: #ffe3d3;
  --theme-color7: #ffeccc;
}

html {
  font-size: 62.5%; /* 16px x 62.5 = 10px = 1rem */
  box-sizing: border-box;
}
body {
  margin: 0;
  height: 100vh;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root{
  height: 100%;
}
/* Layout */
.grid-container {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-columns: 1fr;
 /* grid-template-rows: 10rem 1fr 5rem; */
  grid-template-rows: minmax(min-content, max-content) 1fr 7rem; 
 
  height: 100%;
}
header {
  grid-area: header;
  /* background-color: #203040;*/
  background-color: var(--theme-color4);
 padding: 0.5rem;
}
main {
  grid-area: main;
  padding: 0rem;
  background-origin: border-box;

}
footer {
  grid-area: footer;
  /* background-color: #203040; */
  background-color: var(--theme-color4);
  color: #ffffff;
  padding-top: 0.2rem;
  height: 7 rem;
  font-size: 1.1rem;
  padding: 1rem;
}

footer a {
  color: #ffffff;
  text-decoration: underline;
}

footer a:hover {
  /* color: #ff8000; */
  color: var(--theme-color2);
}

/* Slider */
label.logo_switch{
  margin-bottom: 1rem;
}


/* shop */
div.shop_footer{
  font-size: 1.4rem;
  margin:auto;
  padding-top: 0.3rem;
}

/* Common */
h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.6rem;
  padding: 1rem 0;
}
h3 {
  font-size: 1.4rem;
  
}

a {
  text-decoration: none;
  color: var(--theme-color4);
}
a:hover {
  /*color: #ff8000;*/
  color: var(--theme-color2);
}
ul{
  padding: 0;
  margin: 0;
  list-style-type: none;
}
li{
  margin-top: 1rem;
}

ul.indent li{
  padding-left: 2rem;
}

.theme-header, .theme-label{
  color: var(--theme-color4);
}

input, select, textarea, button {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--theme-color4);
}
button {
  background-color: #f8f8f8;
  cursor: pointer;
}
input:hover, select:hover, textarea:hover, button:hover{
  border: 0.1rem #404040 solid;
}
button.primary {
 /* background-color: #f0c040;*/
  background-color: var(--theme-color2);
}

button.menu{
  width: 20rem;
}
button.block{
  width: 100%;
}
button.small{
  font-size: 1.2rem;
}
button.list_action{
  width: 6rem;
  
}
/* Header */
header a {
  color: #ffffff;
  padding: 1rem;
}
a.brand {
  color: #ffffff;
  font-size: 2rem;
  font-weight: bold;
}
div.greyed-out-link{
  padding: 1rem;
  color: gray;
}
.badge{
  background-color: #f02020;
  color: #ffffff;
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.row.center {
  justify-content: center;
}
.row.top{
  align-items: flex-start;
}
.row.start {
  justify-content: flex-start;
}
.col-1{
  flex: 1 1 25rem;
}
.col-2{
  flex: 2 1 50rem;
}
.col-3{
  flex: 3 1 75rem;
}
.min-30 {
  min-width: 30rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.shop_subtitle{
  color: gray;
  text-align: center;
}

button.hamburger-right{
  display: none;
}

/* Count in stock */
.in_stock {
  color: green;
}
.not_in_stock {
  color:red;
}

/* Image */
img {
  border-radius: 0.5rem;
}
img.medium {
  max-width: 29rem;
  width: 100%;
}
img.large{
  max-width: 40rem;
  width: 100%;
}
input#imageFile{
  border: none;
}

/* About text */
div.about-text{
  padding:5rem;
  max-width: 100rem;
}

div.banner {
  background-image: url("cropped-flatland.jpg");
  background-color: #cccccc;
  background-repeat: repeat;
  height: 20rem;
 }


 div.shop-title{
  /* display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%; */
  height: 14rem;
  padding-top: 6rem;
 }

 div.shop-title-no-subtitle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

h1.seller-subtitle{
  margin-top:0;
  font-size: 1.8rem;
}

 button.about{
   margin: 2rem;
   /* background-color: var(--theme-color1); */
   /*border-color: var(--theme-color3);*/
   border-color: 1rem solid #a4a4a4;
   color: var(--theme-color4);
   /* font-size: 1.4rem; */
  padding-top: 1.4rem; 
 }

 button.about:hover{
  /* border-color: var(--theme-color2); */
  border: 0.1rem #404040 solid;
 }

/* Card */
.card {
  border: 0.1rem #c0c0c0 solid; 
  /* border: 0.1rem solid var(--theme-color4); */
  /*background-color: var(--theme-color3);*/
  /*background-color: #f8f8f8;*/
  border-radius: 0.5rem;
  margin: 1rem;
  color: var(--theme-color4);

}
.form-card{
  color: var(--theme-color4);
  /* background-color: var(--theme-color3); */
  border: 0rem var(--theme-color4) solid;
  margin: 1rem;
}
.card-body {
  padding: 1rem;
}
.card-body > * {
  margin-bottom: 0.5rem;
}
.seller-description{
  white-space: pre-wrap;
  text-align: center;
  line-height: 200%;
  max-width: 75rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
}

.seller-info{
  max-width: 75rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: var(--theme-color3);
  border-radius: 1rem;
  color: var(--theme-color4);
}

.seller-info a{
  text-decoration: underline;;
}

#seller_organisation_number{
  max-width:10rem;
}


#name_text_color, 
#subtitle_text_color, 
#seller_description_background_color,  
#seller_description_text_color, 
#seller_footer_background_color, 
#seller_footer_text_color,
#seller_products_background_color
{
  max-width:8rem;
}

.privacy-policy{
  max-width: 75rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  border-radius: 1rem;
  color: var(--theme-color4);
  font-size: 1rem;
}
.privacy-policy h3{
  margin-top:3rem;
}
h1.seller-name-header{
  font-size:4rem;
  margin:0;
}
.package-info{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 20rem;
  height: 20rem;
  padding: 2rem;
  text-align: center;
}
.package-info-active{
  color:  var(--theme-color4);
  cursor: pointer;
}
.package-info-inactive{
  color:  #AAA;
}
.package-info-active:hover{
  border: 0.1rem solid var(--theme-color2)
}

.package-info h2{
  margin: 0;
}
.seller-info-text{
  padding:5rem;
  max-width: 100rem;
  text-align: center;
}
.price {
  font-size: 2rem;
}

div.product-area{
  max-width: 80rem;
  margin: auto;
}

div.product-reviews{
  color: var(--theme-color4);
}

div.product-description{
  color: var(--theme-color4);
}

div.product-shipping-price-per-item{
  font-size: 1.2rem;
}
div.product-shipping-info{
  font-size: 1.2rem;
}

div.product-card{
  height: 42rem;
  width: 25rem;
  padding: 1rem;
  display: flex;                   /* defines flexbox */
  flex-direction: column;          /* top to bottom */
  justify-content: space-between;  /* first item at start, last at end */
}

div.product-image-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height:25rem;
  width:25rem;
}

img.product-image{
  max-height: 25rem;
  max-width: 25rem;
}

div.seller-card{
  max-width:40rem;
  margin:auto;
  padding:3rem;
}


/* Rating */
.rating span {
  color: #f0c040;
  margin: 0.1rem;
}
.rating span:last-child{
  color:#404040;
}
/* Alert */
.loading{
  display: block !important;
}
.success {
  color: #20a020;
}
.danger {
  color:#a02020;
}
.pending {
  color: #2020a0;
}
.alert {
  padding: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}

.alert-info{
  /* color: #2020a0; */

  /* background-color: #e0e0ff; */
  color: var(--theme-color4);
  background-color: var(--theme-color1);
}

.alert-danger{
  color: #a02020;
  background-color: #ffe0e0;
}

.alert-success{
  color: #20a020;
  background-color: #eeffe0;
}

img.small{
  max-width: 5rem;
  width: 100%;
}




/* FORM*/
label.form-label {
  font-size: 1rem;
}
.form{
  max-width: 60rem;
  margin: 0 auto;
  padding: 1rem;
}
.form > div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
.form label {
  margin-top: 1rem;
}

/* Dropdown */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content{
  position: absolute;
  display: none;
  
  min-width: 13rem;
  padding: 1rem;
  z-index:1;
  background-color: #203040;
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}
.dropdown:hover .dropdown-content{
  display: block;
}
.dropdown-content.right{
  left: 0;
}
.dropdown-content.left{
  right: 0;
}

/*Checkout steps */
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: #f08000;
  color: #f08000;
}

/* Beta-tag */
img.beta-tag {
  opacity: 0;
}

/* TAble */
.table {
  width: 100%;
  border-collapse: collapse;
}
.table tbody tr:nth-of-type(odd){
  background-color: #f4f4f4;
}
.table td, .table th {
  text-align: left;
  border: 0.1rem solid #e4e4e4;
  padding: 0.5rem;
}
.table button{
  margin: 0 0.2rem;
}

table.small_screen_table{
  border-collapse: separate;
  border-spacing: 1.5rem 1rem;
}
table.small_screen_table td{
  border: none;
  padding-right: 0.2rem;
  font-size:1.4rem;
}
table.small_screen_table td button{
  margin:1rem;
}

.table{
  color: var(--theme-color4);
}
/* table.small_screen_table tr:nth-of-type(odd){
  background-color: #f4f4f4;
} */

div.small_screen{
  display:none;
}
div.small_screen_card{
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
 
  padding:2rem;
 
  border: 0.1rem #c0c0c0 solid;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  width: 80vw;
}


/* Carousel */
/* .slider-wrapper{
  background-image: url("flygbild1.jpg")  center center;
} */

div.top_seller{
 
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 20rem;
  width: 15rem;
  text-align: center;
}

img.top_seller_img{
  max-width: 15rem;
  max-height: 15rem;
}

div.logo-container{
  width: 15rem;
  height: 15rem;
}

div.no-logo-container{
  display: flex;
  flex-direction: column;
  height: 20rem;
  justify-content: center;
  align-items: center;
}

div.top_seller_name{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 6rem;
  max-width: 15rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}


.carousel .slide img {
  max-width: 20rem;
  max-height: 20rem;
}

/* Search */
.search button {
  border-radius: 0 0.5rem 0.5rem 0;
  border-right: none;
  margin-right: 0.5rem;
}

.search input {
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: none;
  margin-left: 0.5rem;
}



.active {
  font-weight: bold;
}

/* Aside */
aside {
  position: fixed;
  width: 30rem;
  height: 100%;
  background-color: #efefef;
  z-index: 1000;
  transform: translateX(-30rem);
  display:none;
}

aside.open {
  transform: translateX(0);
  display:block;
}

aside.right {
  position: fixed;
  width: 30rem;
  height: 100%;
  background-color: #efefef;
  z-index: 1000;
  transform: translateX(100vw);
  display:none;
}

aside.right-open {
  transform: translateX(calc(100vw - 30rem));
  display:none;
}

button.open-sidebar{
  font-size: 3rem;
  padding: 0.2rem 0.5rem;
  margin: 0 0.5rem;
  background: none;
  color: #ffffff;
  cursor: pointer;
}

button.open-sidebar-right{
  font-size: 3rem;
  padding: 0.2rem 0.5rem;
  margin: 0.5rem 0;
  background: none;
  color: #ffffff;
  cursor: pointer;
}

button.open-sidebar:hover {
  border-color: #ffffff;
}

aside ul {
  padding:0;
  list-style: none;
}

aside li {
  display:flex;
  justify-content: space-between;
  padding: 1rem;
}

button.close-sidebar {
  padding: 0.3rem 0.8rem;
}

/* Map */
.full-container {
  margin: -1rem;
  height: calc(100% + 2rem);
}

.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem;
  width: 25rem;
  height: 4rem;
  display: flex;
}

.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}

.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}

/* Pagination */

.pagination a{
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pagination a.active {
  font-weight: bold;
}

.pagination button{
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pagination button.active {
  font-weight: bold;
}

/* Snackbar */
div#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  /* background-color: #333; */
  background-color:  #4472c4;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

div#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}


.break-all{ word-break: break-all;}

.shop-open{
  color: #44a775;
}

div.user-profile input{
  color: var(--theme-color4);
}

div.signin{
  color: var(--theme-color4);
}

div.signin a{
  text-decoration: underline;
}

div.forgot-password{
  color: var(--theme-color4);
}

div.reset-password{
  color: var(--theme-color4);
}



@media only screen and (max-width: 440px) {
  div.top_seller {
    height: auto;
    width: 75%;
  }

  div.no-logo-container {
    height: 5rem;
  }

  div.logo-container {
    height: 5rem;
    width: 5rem;
  }

  img.top_seller_img{
    height: 5rem;
  }

  div.top_seller_container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  div.top_seller_name{
    height: auto;
  }

 .broad_screen{
    display:none;
  }
  div.small_screen{
    display: block;
  }
 
  
  button.hamburger-right{
    display: block;
  }

  .search{
    display: none;
  }

  div.mobile-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .slim-on-mobile{
    padding:0;
  }
 
  div.settings{
    width: 95vw;
    margin: 0 auto;
    padding: 1rem;
  }

  div.settings input{
    max-width: 93%;
  }

  button.small.list_action{
    margin-top:0;
    margin-bottom:0;
  }

  aside.right-open{
    display:block;
  }
  
}